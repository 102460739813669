
import { defineComponent } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderIntroBoxBrands from '../../../components/Frontend/Brands/HeaderIntroBoxBrands.vue';
import BrandsBoxMarketplaces from '../../../components/Frontend/Brands/BrandsBoxMarketplaces.vue';
import MarkenBoxen from '../../../components/Frontend/MarkenBoxen.vue';
import Footer from '../../../components/Frontend/Footer.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Service Partner | Styria Media Groupp',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: 'Die Services der STYRIA: Druck, IT, Agenturen, Kundenmanagement, Logistik, Rechnungswesen.',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'Service partner | Stryria Media Group',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: "STYRIA's services: printing, IT, agencies, customer management, logistics, accounting.",
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderIntroBoxBrands,
    MarkenBoxen,
    BrandsBoxMarketplaces,
    Footer,
  },
  setup() {


    return {
     
    };
  },
});
